import {goToByScroll} from "./go-to-by-scroll";

const _window = $(window);
const body    = $('body');


$('.hero-scroll', '.hero-home').on('click', function () {
	goToByScroll($('.sezione-0', '.home'), 0);
});

$('.hero-scroll', '.page-template-page-il-gruppo').on('click', function () {
	goToByScroll($('.sezione-1', '.page-template-page-il-gruppo'), 0);
});



// play video when clicking on cover

$('.video__cover').on('click', function (e) {

	$(this).fadeOut(1200);

	$(".video iframe")[0].src += "&autoplay=1";
	e.preventDefault();

});


// open form contatti on bisogno change

$('#bisogno', '.cta-contatti').on('change', function (e) {
	e.stopPropagation();

	const bisogno = $(this).val();

	setTimeout(function () {
		$('input[name=bisogno]', '.wpcf7-form').val(bisogno)

		openPopup('contatti-popup');
	}, 50);

	return false;
});


//================================= SCROLLMAGIC STEPS ANIMATIONS

let steps = $('img', '.settori-slider__images');

if (steps.length > 0)
{
	// init controller
	let controller = new ScrollMagic.Controller();

	function initStepsAnimations() {

		// use a function to automatically adjust the duration
		let durationValueCache;

		function getDuration() {
			return durationValueCache;
		}

		function updateDuration(e) {
			durationValueCache = steps.outerHeight(true) - _window.height();
		}

		$(window).off().on('resizeEnd', updateDuration); // update the duration when the window size changes

		$(window).triggerHandler('resizeEnd'); // set to initial value


		steps.each(function () {
			let step = $(this);

			new ScrollMagic.Scene({
				triggerElement: step[0],
				duration:       step.outerHeight(),
				triggerHook:    'onCenter'
			})
				.on("enter leave", function (e) {
					if (e.type === 'enter')
					{
						// inside
						let sectionId = step.attr('data-term');

						$('a[data-term=' + sectionId + ']', '.settori-slider__terms').addClass('active text-gradient').siblings().removeClass('active text-gradient');

						$('span', '.settori-slider__counter').text(step.index() + 1)
					}
					else
					{
						// outside
					}
				})
				//.addIndicators()
				.addTo(controller);
		});

		updateDuration();
	}

	_window.on('resizeEnd', function () {
		// destroy controller with scene reset
		if (typeof controller !== 'undefined')
		{
			controller.destroy(true);
		}

		initStepsAnimations();
	});

	_window.on('load', function () {
		initStepsAnimations();
	});
}


if (body.hasClass('home'))
{
	let homeController = new ScrollMagic.Controller();

	new ScrollMagic.Scene({
		triggerElement: $('.home .sezione-1')[0],
		duration:       $('.home .sezione-1').height(),
		triggerHook:    'onCenter'
	})
		.on("enter leave", function (e) {
			if (e.scrollDirection === 'FORWARD')
			{
				body.addClass('bg-white');
			}
			else
			{
				body.removeClass('bg-white');
			}
		})
		//.addIndicators()
		.addTo(homeController);
}


/**
 * Open hidden fields when the e-mail field in the "Maichimp for Wordpress" wigdet gains focus
 */
$('input[type=email]', '#site-footer .mc4wp-form').on('focus', function () {
	$('.hidden-fields', '#site-footer .mc4wp-form').slideDown();
});


$('.mc4wp-form .btn').on('click', function () {
	if ($('input[name=AGREE_TO_TERMS]').prop('checked') !== true)
	{
		$('.mc4wp-form .privacy label').addClass('error');
	}
	else
	{
		$('.mc4wp-form .privacy label').removeClass('error');
	}
});


$('input[name=AGREE_TO_TERMS]').on('click', function () {
	if ($(this).prop('checked') !== true)
	{
		$('.mc4wp-form .privacy label').addClass('error');
	}
	else
	{
		$('.mc4wp-form .privacy label').removeClass('error');
	}
});



const misure_array = {
	'standard':                [
		[
			"6,00",
			"5,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"5,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"10,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"10,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"15,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"15,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"20,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"20,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"25,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"25,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"30,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"30,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"35,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"35,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"40,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"40,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"45,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"45,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"50,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"50,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"55,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"55,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"60,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"60,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"65,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"65,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"70,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"70,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"Oltre",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"Oltre",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"8,00",
			"5,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"5,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"5,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"10,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"10,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"10,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"15,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"15,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"15,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"20,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"20,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"20,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"25,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"25,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"25,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"30,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"30,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"30,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"35,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"35,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"35,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"40,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"40,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"40,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"45,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"45,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"45,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"50,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"50,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"50,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"55,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"55,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"55,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"60,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"60,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"60,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"65,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"65,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"65,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"70,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"70,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"70,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"Oltre",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"Oltre",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"Oltre",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"10,00",
			"5,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"5,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"5,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"10,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"10,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"10,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"15,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"15,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"15,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"20,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"25,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"30,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"35,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"40,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"45,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"50,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"55,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"60,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"65,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"70,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"75,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"80,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"85,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"90,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"95,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"100,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"105,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"110,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"115,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"120,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"125,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"130,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"135,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"140,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"145,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"150,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"155,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"160,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"165,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"170,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"175,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"180,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"Oltre",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"Oltre",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"Oltre",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"12,00",
			"5,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"5,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"5,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"10,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"10,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"10,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"15,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"15,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"15,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"20,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"25,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"30,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"35,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"40,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"45,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"50,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"55,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"60,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"65,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"70,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"75,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"80,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"85,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"90,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"95,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"100,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"105,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"110,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"115,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"120,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"125,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"130,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"135,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"140,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"145,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"150,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"155,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"160,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"165,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"170,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"175,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"180,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"Oltre",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"Oltre",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"Oltre",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"15,00",
			"5,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"5,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"5,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"20,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"25,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"30,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"35,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"40,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"45,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"50,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"55,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"60,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"65,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"70,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"75,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"80,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"85,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"90,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"95,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"100,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"105,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"110,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"115,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"120,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"125,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"130,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"135,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"140,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"145,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"150,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"155,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"160,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"165,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"170,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"175,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"180,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"17,00",
			"5,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"5,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"5,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"10,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"10,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"10,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"15,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"15,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"15,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"20,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"25,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"30,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"35,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"40,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"45,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"50,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"55,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"60,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"65,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"70,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"75,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"80,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"85,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"90,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"95,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"100,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"105,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"110,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"115,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"120,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"125,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"130,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"135,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"140,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"145,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"150,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"155,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"160,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"165,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"170,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"175,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"180,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"Oltre",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"Oltre",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"Oltre",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"20,00",
			"5,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"5,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"5,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"10,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"10,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"10,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"15,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"15,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"15,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"20,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"25,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"30,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"35,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"40,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"45,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"50,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"55,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"60,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"65,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"70,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"75,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"80,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"85,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"90,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"95,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"100,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"105,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"110,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"115,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"120,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"125,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"130,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"135,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"140,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"145,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"150,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"155,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"160,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"165,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"170,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"175,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"180,00",
			"gamba 5,00 | colmo 8,26"
		],
		[
			"20,00",
			"Oltre",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"Oltre",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"Oltre",
			"gamba 5,00 | colmo 8,26"
		],
		[
			"25,00",
			"5,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"5,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"5,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"10,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"10,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"10,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"15,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"15,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"15,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"20,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"25,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"30,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"35,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"40,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"45,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"50,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"55,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"60,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"65,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"70,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"75,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"80,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"85,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"90,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"95,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"100,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"105,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"110,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"115,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"120,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"125,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"130,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"135,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"140,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"145,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"150,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"155,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"160,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"165,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"170,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"175,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"180,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"Oltre",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"Oltre",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"Oltre",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"30,00",
			"5,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"5,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"5,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"20,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"25,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"30,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"35,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"40,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"45,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"50,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"55,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"60,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"65,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"70,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"75,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"80,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"85,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"90,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"95,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"100,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"105,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"110,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"115,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"120,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"125,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"130,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"135,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"140,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"145,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"150,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"155,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"160,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"165,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"170,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"175,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"180,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 5,00 | colmo 9,90"
		]
	],
	'gazebo':                  [
		[
			"3,00",
			"3,00",
			"gamba 2,60 | colmo 4,10"
		],
		[
			"3,00",
			"4,00",
			"gamba 2,60 | colmo 4,30"
		],
		[
			"3,00",
			"5,00",
			"gamba 2,60 | colmo 4,55"
		],
		[
			"3,00",
			"6,00",
			"gamba 2,60 | colmo 4,80"
		],
		[
			"4,00",
			"4,00",
			"gamba 2,60 | colmo 4,50"
		],
		[
			"4,00",
			"5,00",
			"gamba 2,60 | colmo 4,70"
		],
		[
			"4,00",
			"6,00",
			"gamba 2,60 | colmo 4,95"
		],
		[
			"5,00",
			"5,00",
			"gamba 2,60 | colmo 4,90"
		],
		[
			"5,00",
			"6,00",
			"gamba 2,60 | colmo 5,10"
		],
		[
			"6,00",
			"6,00",
			"gamba 2,60 | colmo 5,30"
		]
	],
	'abside':                  [
		[
			"8,00",
			"5,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"5,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"5,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"10,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"10,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"10,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"15,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"15,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"15,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"20,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"25,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"30,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"35,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"40,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"45,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"50,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"55,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"60,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"65,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"70,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"75,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"80,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"85,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"90,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"95,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"100,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"105,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"110,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"115,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"120,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"125,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"130,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"135,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"140,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"145,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"150,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"155,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"160,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"165,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"170,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"175,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"180,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"Oltre",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"Oltre",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"Oltre",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"10,00",
			"5,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"5,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"5,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"10,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"10,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"10,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"15,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"15,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"15,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"20,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"25,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"30,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"35,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"40,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"45,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"50,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"55,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"60,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"65,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"70,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"75,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"80,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"85,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"90,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"95,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"100,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"105,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"110,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"115,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"120,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"125,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"130,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"135,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"140,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"145,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"150,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"155,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"160,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"165,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"170,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"175,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"180,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"Oltre",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"Oltre",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"Oltre",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"12,00",
			"5,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"5,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"5,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"10,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"10,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"10,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"15,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"15,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"15,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"20,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"25,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"30,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"35,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"40,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"45,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"50,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"55,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"60,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"65,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"70,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"75,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"80,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"85,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"90,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"95,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"100,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"105,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"110,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"115,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"120,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"125,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"130,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"135,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"140,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"145,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"150,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"155,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"160,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"165,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"170,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"175,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"180,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"Oltre",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"Oltre",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"Oltre",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"15,00",
			"5,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"5,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"5,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"20,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"25,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"30,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"35,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"40,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"45,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"50,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"55,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"60,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"65,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"70,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"75,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"80,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"85,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"90,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"95,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"100,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"105,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"110,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"115,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"120,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"125,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"130,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"135,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"140,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"145,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"150,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"155,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"160,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"165,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"170,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"175,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"180,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"17,00",
			"5,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"5,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"10,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"10,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"15,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"15,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"20,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"25,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"30,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"35,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"40,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"45,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"50,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"55,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"60,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"65,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"70,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"75,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"80,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"85,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"90,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"95,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"100,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"105,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"110,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"115,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"120,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"125,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"Oltre",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"Oltre",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"20,00",
			"5,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"5,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"5,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"10,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"10,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"10,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"15,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"15,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"15,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"20,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"25,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"30,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"35,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"40,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"45,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"50,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"55,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"60,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"65,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"70,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"75,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"80,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"85,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"90,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"95,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"100,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"105,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"110,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"115,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"120,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"125,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"130,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"135,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"140,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"145,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"150,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"155,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"160,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"165,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"170,00",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"175,00",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"180,00",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"20,00",
			"Oltre",
			"gamba 3,00 | colmo 6,30"
		],
		[
			"20,00",
			"Oltre",
			"gamba 4,00 | colmo 7,30"
		],
		[
			"20,00",
			"Oltre",
			"gamba 5,00 | colmo 8,30"
		],
		[
			"25,00",
			"5,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"5,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"5,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"10,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"10,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"10,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"15,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"15,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"15,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"20,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"25,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"30,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"35,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"40,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"45,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"50,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"55,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"60,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"65,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"70,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"75,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"80,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"85,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"90,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"95,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"100,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"105,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"110,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"115,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"120,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"125,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"130,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"135,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"140,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"145,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"150,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"155,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"160,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"165,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"170,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"175,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"180,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"Oltre",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"Oltre",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"Oltre",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"30,00",
			"5,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"5,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"5,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"20,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"25,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"30,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"35,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"40,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"45,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"50,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"55,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"60,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"65,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"70,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"75,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"80,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"85,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"90,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"95,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"100,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"105,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"110,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"115,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"120,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"125,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"130,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"135,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"140,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"145,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"150,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"155,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"160,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"165,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"170,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"175,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"180,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 5,00 | colmo 9,90"
		]
	],
	'ottagonale-dodecagonale': [
		[
			"10,00",
			"10,00 Ottagono",
			"gamba 3,00 | colmo 4,80"
		],
		[
			"10,00",
			"10,00 Ottagono",
			"gamba 4,00 | colmo 5,80"
		],
		[
			"10,00",
			"10,00 Ottagono",
			"gamba 5,00 | colmo 6,80"
		],
		[
			"15,00",
			"15,00 Ottagono",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"15,00 Ottagono",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"15,00 Ottagono",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"20,00",
			"20,00 Ottagono",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"20,00 Ottagono",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"30,00",
			"30,00 Dodecagono",
			"gamba 4,00 | colmo 8,90"
		]
	],
	'elis':                    [
		[
			"10,00",
			"10,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"15,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"20,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"25,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"30,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"35,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"40,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"45,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"50,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"55,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"60,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"65,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"70,00",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"10,00",
			"Oltre",
			"gamba 3,00 | colmo 4,70"
		],
		[
			"12,00",
			"10,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"15,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"20,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"25,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"30,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"35,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"40,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"45,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"50,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"55,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"60,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"65,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"70,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"12,00",
			"Oltre",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"14,00",
			"10,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"15,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"20,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"25,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"30,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"35,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"40,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"45,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"50,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"55,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"60,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"65,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"70,00",
			"gamba 3,00 | colmo 5,00"
		],
		[
			"14,00",
			"Oltre",
			"gamba 3,00 | colmo 5,00"
		]
	],
	'abside-cuspide':          [
		[
			"10,00",
			"20,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"25,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"30,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"35,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"40,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"45,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"50,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"55,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"60,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"65,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"70,00",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"10,00",
			"Oltre",
			"gamba 3,00 | colmo 4,80 | cuspide 8,30"
		],
		[
			"15,00",
			"25,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"30,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"35,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"40,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"45,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"50,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"55,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"60,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"65,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"70,00",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		],
		[
			"15,00",
			"Oltre",
			"gamba 3,00 | colmo 5,50 | cuspide 8,70"
		]
	],
	'curve-tent':              [
		[
			"17,50",
			"9,00",
			"gamba 3,00 | colmo 6,40"
		],
		[
			"17,50",
			"12,00",
			"gamba 3,00 | colmo 6,40"
		],
		[
			"17,50",
			"15,00",
			"gamba 3,00 | colmo 6,40"
		],
		[
			"17,50",
			"18,00",
			"gamba 3,00 | colmo 6,40"
		],
		[
			"17,50",
			"21,00",
			"gamba 3,00 | colmo 6,40"
		]
	],
	'silver':                  [
		[
			"20,00",
			"15,00",
			"gamba 4,00 (5,15 sopra fascione)"
		],
		[
			"20,00",
			"20,00",
			"gamba 4,00 (5,15 sopra fascione)"
		]
	],
	'carbon':                  [
		[
			"5,00 (carbon-lite)",
			"5,00",
			"gamba 3,00 | colmo 3,60 | fascione 3,80"
		],
		[
			"10,00",
			"10,00",
			"gamba 4,00 | colmo 5,00 | fascione 5,40"
		],
		[
			"10,00",
			"15,00",
			"gamba 4,00 | colmo 5,00 | fascione 5,40"
		],
		[
			"10,00",
			"20,00",
			"gamba 4,00 | colmo 5,00 | fascione 5,40"
		],
		[
			"10,00",
			"25,00",
			"gamba 4,00 | colmo 5,00 | fascione 5,40"
		],
		[
			"10,00",
			"30,00",
			"gamba 4,00 | colmo 5,00 | fascione 5,40"
		],
		[
			"15,00",
			"10,00",
			"gamba 4,00 | colmo 5,20 | fascione 5,40"
		],
		[
			"15,00",
			"15,00",
			"gamba 4,00 | colmo 5,20 | fascione 5,40"
		],
		[
			"15,00",
			"20,00",
			"gamba 4,00 | colmo 5,20 | fascione 5,40"
		],
		[
			"15,00",
			"25,00",
			"gamba 4,00 | colmo 5,20 | fascione 5,40"
		],
		[
			"15,00",
			"30,00",
			"gamba 4,00 | colmo 5,20 | fascione 5,40"
		]
	],
	'poligonale':              [
		[
			"19,60",
			"20,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"20,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"25,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"25,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"30,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"30,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"35,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"40,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"45,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"50,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"55,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"60,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"65,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"70,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"75,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"80,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"85,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"90,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"95,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"100,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"105,00",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"110,00",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"19,60",
			"Oltre",
			"gamba 4,00 | colmo 9,90"
		],
		[
			"19,60",
			"Oltre",
			"gamba 5,00 | colmo 10,90"
		],
		[
			"23,00",
			"20,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"20,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"25,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"25,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"30,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"30,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"35,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"40,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"45,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"50,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"55,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"60,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"65,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"70,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"75,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"80,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"85,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"90,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"95,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"100,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"105,00",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"110,00",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"23,00",
			"Oltre",
			"gamba 4,00 | colmo 10,30"
		],
		[
			"23,00",
			"Oltre",
			"gamba 5,00 | colmo 11,30"
		],
		[
			"24,65",
			"20,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"20,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"25,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"25,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"30,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"30,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"35,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"40,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"45,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"50,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"55,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"60,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"65,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"70,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"75,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"80,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"85,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"90,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"95,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"100,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"105,00",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"110,00",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"24,65",
			"Oltre",
			"gamba 4,00 | colmo 10,45"
		],
		[
			"24,65",
			"Oltre",
			"gamba 5,00 | colmo 11,45"
		],
		[
			"31,50",
			"20,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"20,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"25,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"25,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"30,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"30,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"35,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"40,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"45,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"50,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"55,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"60,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"65,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"70,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"75,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"80,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"85,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"90,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"95,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"100,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"105,00",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"110,00",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"31,50",
			"Oltre",
			"gamba 4,00 | colmo 11,00"
		],
		[
			"31,50",
			"Oltre",
			"gamba 5,00 | colmo 12,00"
		],
		[
			"36,70",
			"20,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"20,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"25,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"25,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"30,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"30,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"35,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"40,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"45,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"50,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"55,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"60,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"65,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"70,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"75,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"80,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"85,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"90,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"95,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"100,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"105,00",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"36,70",
			"110,00",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"Oltre",
			"gamba 5,00 | colmo 12,50"
		],
		[
			"36,70",
			"Oltre",
			"gamba 4,00 | colmo 11,50"
		],
		[
			"50,00",
			"20,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"20,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"25,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"25,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"30,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"30,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"35,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"40,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"45,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"50,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"55,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"60,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"65,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"70,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"75,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"80,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"85,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"90,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"95,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"100,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"105,00",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"110,00",
			"gamba 5,00 | colmo 15,70"
		],
		[
			"50,00",
			"Oltre",
			"gamba 4,00 | colmo 14,70"
		],
		[
			"50,00",
			"Oltre",
			"gamba 5,00 | colmo 15,70"
		]
	],
	'starship':                [
		[
			"interna 20 | totale fino a 40,5",
			"interna 5 | totale fino a 45,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"interna 10 | totale fino a 50,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"interna 15 | totale fino a 55,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"interna 20 | totale fino a 60,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"interna 25 | totale fino a 65,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"interna 30 | totale fino a 70,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"interna 35 | totale fino a 75,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"interna 40 | totale fino a 80,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"Interna 45 | totale fino a 85,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"Interna 50 | totale fino a 90,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"Interna 55 | totale fino a 95,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"Interna 60 | totale fino a 100,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"Interna 65 | totale fino a 105,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"Interna 70 | totale fino a 110,5",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 20 | totale fino a 40,5",
			"Oltre",
			"gamba 4 | colmo 7,2"
		],
		[
			"interna 30 | totale fino a 51",
			"interna 5 | totale fino a 56",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"interna 10 | totale fino a 61",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"interna 15 | totale fino a 66",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"interna 20 | totale fino a 71",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"interna 25 | totale fino a 76",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"interna 30 | totale fino a 81",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"interna 35 | totale fino a 86",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"interna 40 | totale fino a 91",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"Interna 45 | totale fino a 96",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"Interna 50 | totale fino a 101",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"Interna 55 | totale fino a 106",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"Interna 60 | totale fino a 111",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"Interna 65 | totale fino a 116",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"Interna 70 | totale fino a 121",
			"gamba 4 | colmo 8,9"
		],
		[
			"interna 30 | totale fino a 51",
			"Oltre",
			"gamba 4 | colmo 8,9"
		]
	],
	'magazzino':               [
		[
			"6,00",
			"5,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"5,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"10,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"10,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"15,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"15,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"20,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"20,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"25,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"25,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"30,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"30,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"35,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"35,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"40,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"40,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"45,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"45,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"50,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"50,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"55,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"55,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"60,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"60,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"65,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"65,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"70,00",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"70,00",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"6,00",
			"Oltre",
			"gamba 3,00 | colmo 4,20"
		],
		[
			"6,00",
			"Oltre",
			"gamba 4,00 | colmo 5,20"
		],
		[
			"8,00",
			"5,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"5,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"5,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"10,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"10,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"10,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"15,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"15,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"15,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"20,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"20,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"20,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"25,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"25,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"25,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"30,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"30,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"30,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"35,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"35,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"35,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"40,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"40,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"40,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"45,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"45,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"45,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"50,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"50,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"50,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"55,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"55,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"55,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"60,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"60,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"60,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"65,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"65,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"65,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"70,00",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"70,00",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"70,00",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"8,00",
			"Oltre",
			"gamba 3,00 | colmo 4,55"
		],
		[
			"8,00",
			"Oltre",
			"gamba 4,00 | colmo 5,40"
		],
		[
			"8,00",
			"Oltre",
			"gamba 5,00 | colmo 6,40"
		],
		[
			"10,00",
			"5,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"5,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"5,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"10,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"10,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"10,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"15,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"15,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"15,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"20,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"25,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"30,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"35,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"40,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"45,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"50,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"55,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"60,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"65,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"70,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"75,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"80,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"85,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"90,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"95,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"100,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"105,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"110,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"115,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"120,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"125,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"130,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"135,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"140,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"145,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"150,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"155,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"160,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"165,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"170,00",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"175,00",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"180,00",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"10,00",
			"Oltre",
			"gamba 3,00 | colmo 4,90"
		],
		[
			"10,00",
			"Oltre",
			"gamba 4,00 | colmo 5,70"
		],
		[
			"10,00",
			"Oltre",
			"gamba 5,00 | colmo 6,70"
		],
		[
			"12,00",
			"5,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"5,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"5,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"10,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"10,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"10,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"15,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"15,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"15,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"20,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"25,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"30,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"35,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"40,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"45,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"50,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"55,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"60,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"65,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"70,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"75,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"80,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"85,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"90,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"95,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"100,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"105,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"110,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"115,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"120,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"125,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"130,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"135,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"140,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"145,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"150,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"155,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"160,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"165,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"170,00",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"175,00",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"180,00",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"12,00",
			"Oltre",
			"gamba 3,00 | colmo 5,30"
		],
		[
			"12,00",
			"Oltre",
			"gamba 4,00 | colmo 6,00"
		],
		[
			"12,00",
			"Oltre",
			"gamba 5,00 | colmo 7,00"
		],
		[
			"15,00",
			"5,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"5,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"5,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"10,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"15,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"20,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"25,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"30,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"35,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"40,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"45,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"50,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"55,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"60,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"65,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"70,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"75,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"80,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"85,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"90,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"95,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"100,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"105,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"110,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"115,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"120,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"125,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"130,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"135,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"140,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"145,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"150,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"155,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"160,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"165,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"170,00",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"175,00",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"180,00",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 3,00 | colmo 5,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 4,00 | colmo 6,50"
		],
		[
			"15,00",
			"Oltre",
			"gamba 5,00 | colmo 7,50"
		],
		[
			"17,00",
			"5,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"5,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"5,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"10,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"10,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"10,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"15,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"15,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"15,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"20,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"25,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"30,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"35,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"40,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"45,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"50,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"55,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"60,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"65,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"70,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"75,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"80,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"85,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"90,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"95,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"100,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"105,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"110,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"115,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"120,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"125,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"130,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"135,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"140,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"145,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"150,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"155,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"160,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"165,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"170,00",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"175,00",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"180,00",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"17,00",
			"Oltre",
			"gamba 3,00 | colmo 5,90"
		],
		[
			"17,00",
			"Oltre",
			"gamba 4,00 | colmo 6,90"
		],
		[
			"17,00",
			"Oltre",
			"gamba 5,00 | colmo 7,90"
		],
		[
			"20,00",
			"5,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"5,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"5,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"10,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"10,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"10,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"15,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"15,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"15,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"20,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"25,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"30,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"35,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"40,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"45,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"50,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"55,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"60,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"65,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"70,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"75,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"80,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"85,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"90,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"95,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"100,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"105,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"110,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"115,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"120,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"125,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"130,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"135,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"140,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"145,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"150,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"155,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"160,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"165,00",
			"gamba 5,00 | colmo 8,25"
		],
		[
			"20,00",
			"170,00",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"175,00",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"180,00",
			"gamba 5,00 | colmo 8,26"
		],
		[
			"20,00",
			"Oltre",
			"gamba 3,00 | colmo 6,25"
		],
		[
			"20,00",
			"Oltre",
			"gamba 4,00 | colmo 7,25"
		],
		[
			"20,00",
			"Oltre",
			"gamba 5,00 | colmo 8,26"
		],
		[
			"25,00",
			"5,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"5,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"5,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"10,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"10,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"10,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"15,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"15,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"15,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"20,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"25,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"30,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"35,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"40,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"45,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"50,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"55,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"60,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"65,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"70,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"75,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"80,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"85,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"90,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"95,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"100,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"105,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"110,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"115,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"120,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"125,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"130,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"135,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"140,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"145,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"150,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"155,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"160,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"165,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"170,00",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"175,00",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"180,00",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"25,00",
			"Oltre",
			"gamba 3,00 | colmo 7,30"
		],
		[
			"25,00",
			"Oltre",
			"gamba 4,00 | colmo 8,10"
		],
		[
			"25,00",
			"Oltre",
			"gamba 5,00 | colmo 9,10"
		],
		[
			"30,00",
			"5,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"5,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"5,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"10,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"15,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"20,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"25,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"30,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"35,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"40,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"45,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"50,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"55,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"60,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"65,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"70,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"75,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"80,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"85,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"90,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"95,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"100,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"105,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"110,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"115,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"120,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"125,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"130,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"135,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"140,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"145,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"150,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"155,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"160,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"165,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"170,00",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"175,00",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"180,00",
			"gamba 5,00 | colmo 9,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 3,00 | colmo 7,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 4,00 | colmo 8,90"
		],
		[
			"30,00",
			"Oltre",
			"gamba 5,00 | colmo 9,90"
		]
	]
};


if (body.hasClass('page-template-page-preventivo'))
{
	const tipologiaPreventivoInput = $('input[name="tipologia-preventivo"]');
	const montaggioInput           = $('input[name="montaggio"]');
	const localitaMontaggioInput   = $('input[name="localita-montaggio"]');
	const tipoClienteInput         = $('input[name="tipocliente"]');
	const modelloSelect            = $('select[name=modello]');


	managePreventivoFields(tipologiaPreventivoInput.filter(':checked').val());

	tipologiaPreventivoInput.on('change', function () {

		managePreventivoFields($(this).val());

	});

	function managePreventivoFields(tipologiaPreventivoVal) {

		if (tipologiaPreventivoVal === 'Noleggio')
		{
			$('.acquisto-fields').hide();
			$('.noleggio-fields').show();
			$('.localita-montaggio-inputs').show();

			$('input[type="text"]', '.acquisto-fields').val('');

			$('input:radio', '.acquisto-fields').each(function () {
				$(this).prop('checked', false);
			});

			$('input', '.acquisto-fields').removeAttr('required aria-required').removeClass('wpcf7-validates-as-required wpcf7-validates-as-date');
			$('input', '.noleggio-fields').attr('required', 'required').attr('aria-required', 'true').addClass('wpcf7-validates-as-required wpcf7-validates-as-date');
			$('input', '.localita-montaggio-inputs').attr('required', 'required').attr('aria-required', 'true').addClass('wpcf7-validates-as-required');

			setInizioFineDates();

			$('input[name="data-consegna"]').val('');

			localitaMontaggioInput.val('');
		}
		else if (tipologiaPreventivoVal === 'Acquisto')
		{
			$('.noleggio-fields').hide();
			$('.acquisto-fields').show();
			$('.localita-montaggio-inputs').hide();

			$('input[type="text"]', '.noleggio-fields').val('');

			$('input:radio', '.noleggio-fields').each(function () {
				$(this).prop('checked', false);
			});

			$('input', '.noleggio-fields').removeAttr('required aria-required').removeClass('wpcf7-validates-as-required wpcf7-validates-as-date');
			$('input', '.acquisto-fields').attr('required', 'required').attr('aria-required', 'true').addClass('wpcf7-validates-as-required wpcf7-validates-as-date');
			$('input', '.localita-montaggio-inputs').removeAttr('required aria-required').removeClass('wpcf7-validates-as-required');

			setConsegnaDate();

			$('input[name="data-inizio"]').val('');
			$('input[name="data-fine"]').val('');
		}
		else
		{
			$('.noleggio-fields').hide();
			$('.acquisto-fields').hide();
			$('.montaggio-inputs').hide();
			$('.localita-montaggio-inputs').hide();
		}
	}


	/**
	 * Mostra/nascondi campi montaggio
	 */
	montaggioInput.on('change', function () {

		if ($(this).val() === 'Si')
		{
			$('.localita-montaggio-inputs').show();

			$('input', '.localita-montaggio-inputs').attr('required', 'required').attr('aria-required', 'true').addClass('wpcf7-validates-as-required');
		}
		else
		{
			$('.localita-montaggio-inputs').hide();

			$('input', '.localita-montaggio-inputs').removeAttr('required aria-required').removeClass('wpcf7-validates-as-required');
		}

		localitaMontaggioInput.val('');
	});


	/**
	 * Mostra/nascondi campi azienda
	 */
	tipoClienteInput.on('change', function () {

		if ($(this).val() === 'Azienda')
		{
			$('.azienda-fields').show();
		}
		else
		{
			$('.azienda-fields').hide();
			$('.azienda-fields input').val('');
		}
	});

	$('.azienda-fields input').val('');


	/**
	 * Mostra/nascondi campi gazebo
	 */
	modelloSelect.on('change', function () {

		if ($(this).val() === 'gazebo')
		{
			$('.gazebo-fields').show();

			/*$('.wpcf7-form-control-wrap[data-name="disposizione"] input:radio').each(function () {
				if ($(this).val() === 'uniti')
				{
					$(this).prop('checked', true);
				}
			});*/

			$('input, select', '.gazebo-fields').attr('required', 'required').attr('aria-required', 'true').addClass('wpcf7-validates-as-required');
		}
		else
		{
			$('.gazebo-fields').hide();

			$('.azienda-fields input').val('');

			$('.wpcf7-form-control-wrap[data-name="disposizione"] input:radio').each(function () {
				if ($(this).val() === '')
				{
					$(this).prop('checked', true);
				}
				else
				{
					$(this).prop('checked', false);
				}
			});
			$('.wpcf7-form-control-wrap[data-name="numero"] select').prop('selectedIndex', 0).removeClass('wpcf7-not-valid').removeAttr('aria-invalid');

			$('input, select', '.gazebo-fields').removeAttr('required aria-required').removeClass('wpcf7-validates-as-required wpcf7-validates-as-required');
			$('.wpcf7-not-valid-tip', '.gazebo-fields').remove();
		}
	});


	function setInizioFineDates() {
		/**
		 * Campo data-inizio: data minima = oggi + 1 giorno
		 * Campo data-fine: data minima = data-inizio + 1 giorno
		 */
		const inizio = $('input[name="data-inizio"]');
		const fine   = $('input[name="data-fine"]');

		const date = new Date();

		/*const todayDate =
			date.getFullYear().toString() + '-' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '-' +
			date.getDate().toString().padStart(2, '0');*/

		const tomorrowDate =
			      date.getFullYear().toString() + '-' +
			      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
			      (date.getDate() + 1).toString().padStart(2, '0');

		const afterTomorrowDate =
			      date.getFullYear().toString() + '-' +
			      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
			      (date.getDate() + 2).toString().padStart(2, '0');

		// set initial data-inizio date
		inizio[0].value = tomorrowDate;
		inizio[0].setAttribute('min', tomorrowDate);

		// set initial data-fine date
		fine[0].value = afterTomorrowDate;
		fine[0].setAttribute('min', afterTomorrowDate);

		// set data-fine date on data-inizio date change
		inizio.on('change', function () {
			const fromDateValue = $(this).val();
			const toDate        = new Date(fromDateValue);

			// add 1 day
			toDate.setDate(toDate.getDate() + 1);

			const sDate = toDate.getFullYear().toString() + '-' + (toDate.getMonth() + 1).toString().padStart(2, '0') + '-' + (toDate.getDate()).toString().padStart(2, '0');

			fine.val(sDate);
			fine[0].setAttribute('min', sDate);
			fine.trigger('change');
		});
	}

	setInizioFineDates();

	function setConsegnaDate() {
		/**
		 * Campo data-consegna: data minima = oggi + 1 giorno
		 */
		const consegna = $('input[name="data-consegna"]');

		const date = new Date();

		const tomorrowDate =
			      date.getFullYear().toString() + '-' +
			      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
			      (date.getDate() + 1).toString().padStart(2, '0');

		// set initial data-inizio date
		consegna[0].value = tomorrowDate;
		consegna[0].setAttribute('min', tomorrowDate);
	}

	modelloSelect.attr('disabled', 'disabled');

	$('select[name=evento]').on('change', function () {
		const settore = $(this).val();

		modelloSelect.attr('disabled', 'disabled');

		if (settore !== '')
		{
			let adminAjaxUrl = $('#admin-ajax-url').data('admin-ajax-url');

			$.ajax({
				url:      adminAjaxUrl,
				method:   'POST',
				dataType: 'JSON',
				data:     {
					'action':  'wc_do_ajax',
					'fn':      'getProdottiBySettore',
					'settore': settore
				}
			})
				.done(function (data) {
					if (data.result === 'success' && data.options !== '')
					{
						let options        = JSON.parse(data.options);
						let modelloOptions = $('option:first-child', modelloSelect).get(0).outerHTML;

						Object.keys(options).forEach(function (key) {
							modelloOptions += '<option value="' + key + '">' + options[key] + '</option>';
						});

						modelloSelect.html(modelloOptions).removeAttr('disabled');
						modelloSelect.prop('selectedIndex', 0);

						modelloSelect.removeAttr('disabled');

						// reset larghezza, altezza, lunghezza select
						larghezzaSelect.attr('disabled', 'disabled');
						lunghezzaSelect.attr('disabled', 'disabled');
						altezzaSelect.attr('disabled', 'disabled');
						larghezzaSelect.prop('selectedIndex', 0);
						lunghezzaSelect.prop('selectedIndex', 0);
						altezzaSelect.prop('selectedIndex', 0);
					}

					if (data.result === 'error' && data.options !== '')
					{
						console.log('error in getProdottiBySettore');
					}

				})
				.fail(function (errorThrown) {
					console.log(errorThrown);
				});
		}

		modelloSelect.prop('selectedIndex', 0).trigger('change');
	});

	let misure;
	const larghezzaSelect = $('select[name=larghezza]');
	const lunghezzaSelect = $('select[name=lunghezza]');
	const altezzaSelect   = $('select[name=altezza]');

	larghezzaSelect.attr('disabled', 'disabled');
	lunghezzaSelect.attr('disabled', 'disabled');
	altezzaSelect.attr('disabled', 'disabled');

	modelloSelect.on('change', function () {
		const modello = $(this).val();

		misure = misure_array[modello];

		if (typeof misure !== 'undefined')
		{
			// reset lunghezza, altezzaselect
			lunghezzaSelect.attr('disabled', 'disabled');
			altezzaSelect.attr('disabled', 'disabled');
			lunghezzaSelect.prop('selectedIndex', 0);
			altezzaSelect.prop('selectedIndex', 0);

			let prevLarghezza    = '';
			let larghezzaOptions = '<option value="">' + 'Seleziona larghezza' + '</option>';

			misure.forEach(element => {
				let larghezza = element[0];

				if (prevLarghezza === '' || prevLarghezza !== larghezza)
				{
					larghezzaOptions += '<option value="' + larghezza + '">' + larghezza + '</option>';

					prevLarghezza = larghezza;
				}
			});

			larghezzaSelect.html(larghezzaOptions).removeAttr('disabled');
			larghezzaSelect.prop('selectedIndex', 0);
		}
	});

	larghezzaSelect.on('change', function () {
		const larghezza = $(this).val();

		if (larghezza !== '')
		{
			let prevLunghezza    = '';
			let lunghezzaOptions = '<option value="">' + 'Seleziona lunghezza' + '</option>';

			misure.forEach(element => {
				let larghezzaItem = element[0];
				let lunghezza     = element[1];

				if (larghezza === larghezzaItem && (prevLunghezza === '' || prevLunghezza !== lunghezza))
				{
					lunghezzaOptions += '<option value="' + lunghezza + '">' + lunghezza + '</option>';

					prevLunghezza = lunghezza;
				}
			});

			lunghezzaSelect.html(lunghezzaOptions).removeAttr('disabled');
			lunghezzaSelect.prop('selectedIndex', 0);
		}
	});

	lunghezzaSelect.on('change', function () {
		const larghezza = larghezzaSelect.val();
		const lunghezza = $(this).val();

		if (lunghezza !== '')
		{
			let prevAltezza    = '';
			let altezzaOptions = '<option value="">' + 'Seleziona altezza' + '</option>';

			misure.forEach(element => {
				let larghezzaItem = element[0];
				let lunghezzaItem = element[1];
				let altezza       = element[2];

				if (larghezza === larghezzaItem && lunghezza === lunghezzaItem && (prevAltezza === '' || prevAltezza !== altezza))
				{
					altezzaOptions += '<option value="' + altezza + '">' + altezza + '</option>';

					prevAltezza = altezza;
				}
			});

			altezzaSelect.html(altezzaOptions).removeAttr('disabled');
			altezzaSelect.prop('selectedIndex', 0);
		}
	});
}